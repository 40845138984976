<template>
  <apex-chart type="donut" :height="256" :options="options" :series="series" />
</template>

<script>
// Import the chart component from package
import ApexChart from "vue-apexcharts"

// Import lodash function to deep merge objects
import merge from "lodash/merge"

// Import default chart settings
import apexChartBaseOptions from "@/json/apexChartBaseOptions.js"

// Export the component
export default {
  // Name of the component
  name: "SimilarityChart",

  // Register the children components
  components: {
    ApexChart
  },

  // Incoming data from parent
  props: {
    // The object data for negative/neutral/positive values
    data: {
      // It'll be a key-value pair
      type: Array,
      required: true
    }
  },

  // Define computable properties for this component
  computed: {
    /**
     * The individual data array for the graph
     *
     * @returns {Array}
     */
    series() {
      const relevant = this.data.filter((v) => v >= 0.5).length
      const irrelevant = this.data.length - relevant

      return [(irrelevant * 100) / this.data.length, (relevant * 100) / this.data.length]
    },

    /**
     * The options settings for this chart
     */
    options() {
      return merge(apexChartBaseOptions(), {
        labels: ["Irrelevant", "Relevant"],
        colors: [
          // Irrelevant (dark gray)
          "#a4a4a4",
          // Relevant (green)
          "#067518"
        ],
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        stroke: { colors: ["#fff"] },
        legend: {
          show: true,
          floating: true,
          horizontalAlign: "center",
          position: "top",
          labels: {
            colors: ["#000000"],
            useSeriesColors: false
          },
          offsetY: 12
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            opacity: 0.6
          },
          style: {
            fontSize: "9px",
            colors: ["#fff", "#fff", "#fff"]
          }
        },
        tooltip: {
          fillSeriesColor: true,
          y: {
            formatter: (seriesName) => numeral.fPercent(seriesName)
          }
        },
        plotOptions: {
          pie: {
            size: 260,
            startAngle: 0,
            endAngle: 360,
            offsetY: 30,
            donut: {
              labels: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        }
      })
    }
  },

  mounted() {
    window.setTimeout(() => {
      // To fix chart not loading at first @link: https://github.com/apexcharts/vue-apexcharts/issues/185#issuecomment-642836287
      window.dispatchEvent(new Event("resize"))
    }, 50)
  }
}
</script>
